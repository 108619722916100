<template>
    <div>
      <div>
        <!-- <div class="input-group mb-3">
            <input type="text" class="form-control" placeholder="Search by title" v-model="title" />
            <div class="input-group-append">
              <button class="btn btn-outline-secondary" type="button" @click="searchTitle">
                Search
              </button>
            </div>
          </div> -->
  
        <div class="form-group">
          <label for="exampleFormControlSelect1"
            ><h5 style="width: 200px">
              <i class="bi bi-person-plus"></i> 교사 등록 관리
            </h5></label
          >
          <span>
            <button type="button" class="btn btn-primary btn-sm" @click="openAddModal">
              신규 등록
            </button>
          </span>
        </div>
      </div>
  
      <div>
        <!-- 이름 검색만 유지 -->
        <div class="form-group row">
          <div class="col-md-4">
            <input type="text" class="form-control" placeholder="이름 검색" v-model="searchName" @input="filterTeachers" />
          </div>
        </div>
      </div>
      <p></p>
  
      <div>
        <!-- 교사 목록 테이블 -->
        <div class="table-responsive">
          <table class="table table-striped table-hover">
            <thead>
              <tr>
                <th>학년</th>
                <th>이름</th>
                <th>휴대폰번호</th>
              </tr>
            </thead>
            <tbody v-if="filteredTeachers.length > 0">
              <tr v-for="teacher in filteredTeachers" :key="teacher.id" @click="openEditModal(teacher)" style="cursor: pointer;">
                <td>{{ teacher.gradename }}</td>
                <td>{{ teacher.name }}</td>                
                <td>{{ teacher.phone }}</td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="5" class="text-center">조회된 교사가 없습니다.</td>
              </tr>
            </tbody>
          </table>
        </div>
  
        <!-- 페이징 -->
        <nav aria-label="Page navigation">
          <ul class="pagination justify-content-center">
            <li class="page-item" :class="{ disabled: currentPage === 1 }">
              <a class="page-link" href="#" @click.prevent="changePage(currentPage - 1)">이전</a>
            </li>
            <li
              v-for="page in displayedPages"
              :key="page"
              class="page-item"
              :class="{ active: currentPage === page }"
            >
              <a class="page-link" href="#" @click.prevent="changePage(page)">{{ page }}</a>
            </li>
            <li class="page-item" :class="{ disabled: currentPage === totalPages }">
              <a class="page-link" href="#" @click.prevent="changePage(currentPage + 1)">다음</a>
            </li>
          </ul>
        </nav>
      </div>
  
      <div
        class="modal fade"
        id="teacherModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="teacherModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="teacherModalLabel" style="color: blue">
                {{ isNew ? '교사 등록' : '교사 정보 수정' }}
              </h5>
            </div>
            <small style="color: gray; margin-left: 20px;"> * 반 등록/수정시 기존 교사가 있는지 확인해주세요. </small>
            <div class="modal-body">
              <div class="form-group">
                <form id="teacherForm" name="teacherForm" @submit.prevent="submitTeacherForm">
                  <div class="form-group">
                    <label for="grade">구분</label>
                    <select class="form-control" id="grade" v-model="form.grade" required>
                      <option value="">조회 선택</option>
                      <option v-for="grade in grades" :key="grade.grade" :value="grade.grade">
                        {{ grade.gradename }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group">
                    <label for="name">이름</label>
                    <input
                      type="text"
                      class="form-control"
                      id="name"
                      v-model="form.name"
                      required
                      placeholder="이름을 입력하세요"
                      :disabled="!isNew"
                    />
                  </div>
                  <div class="form-group" v-if="isNew">
                    <label for="sex">성별</label>
                    <select class="form-control" id="sex" v-model="form.sex" required>
                      <option value="">성별 선택</option>
                      <option value="남">남</option>
                      <option value="여">여</option>
                    </select>
                  </div>
                  <div class="form-group" v-if="isNew">
                    <label for="birthday">생년월일</label>
                    <input
                      type="date"
                      class="form-control"
                      id="birthday"
                      v-model="form.birthday"
                      required
                    />
                  </div>
                  <div class="form-group">
                    <label for="phone">휴대폰번호</label>
                    <input
                      type="text"
                      class="form-control"
                      id="phone"
                      v-model="form.phone"
                      required
                      placeholder="휴대폰번호를 입력하세요"
                      :disabled="!isNew"
                    />
                  </div>
                  <div class="form-group">
                    <label for="passwd">비밀번호</label>
                    <input
                      type="password"
                      class="form-control"
                      id="passwd"
                      v-model="form.passwd"
                      :required="isNew"
                      placeholder="비밀번호를 입력하세요"
                    />
                    <small v-if="!isNew" class="form-text text-muted">변경하지 않으려면 비워두세요</small>
                  </div>
                  <small class="form-text text-muted">※ 사진은 "학생(아동)목록"에서 등록해주세요.</small>
                  <div class="modal-footer">
                    <button type="submit" class="btn btn-primary">
                      {{ isNew ? '등록' : '수정' }}
                    </button>
                    <button type="button" class="btn btn-danger" v-if="!isNew" @click="confirmDelete(form)">
                      탈퇴
                    </button>
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">
                      닫기
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
  
      <!-- 삭제 확인 모달 -->
      <div
        class="modal fade"
        id="deleteModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="deleteModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="deleteModalLabel" style="color: red">교사 탈퇴</h5>
            </div>
            <div class="modal-body">
              <p>정말로 <strong>{{ selectedTeacher.name }}</strong> 교사를 탈퇴시키겠습니까?</p>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-danger" @click="deleteTeacher">탈퇴</button>
              <button type="button" class="btn btn-secondary" data-dismiss="modal">취소</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
    import manageService from "../services/manageService";
  
    import $ from "jquery";
  
    export default {
      name: "TeacherMgmt",
      data() {
        return {
          teachers: [],
          filteredTeachers: [],
          grades: [],
          searchName: "",
          currentPage: 1,
          totalPages: 1,
          totalRecords: 0,
          id: this.$store.state.id,
          grade: this.$store.state.grade,
          phone: this.$store.state.phone,
          name: this.$store.state.name,
          teacherid: this.$store.state.teacherid,
          role: this.$store.state.role,
          token: this.$store.state.token,
          classid: this.$store.state.classid,
          form: {
            id: "",
            name: "",
            sex: "",
            birthday: "",
            phone: "",
            grade: "",
            passwd: "",
            classid: this.$store.state.classid
          },
          isNew: true,
          selectedTeacher: {}
        };
      },
      computed: {
        displayedPages() {
          // 페이지 네비게이션에 표시할 페이지 번호 계산
          const maxDisplayed = 5;
          if (this.totalPages <= maxDisplayed) {
            return Array.from({ length: this.totalPages }, (_, i) => i + 1);
          }
  
          let start = Math.max(1, this.currentPage - Math.floor(maxDisplayed / 2));
          let end = start + maxDisplayed - 1;
  
          if (end > this.totalPages) {
            end = this.totalPages;
            start = Math.max(1, end - maxDisplayed + 1);
          }
  
          return Array.from({ length: end - start + 1 }, (_, i) => start + i);
        }
      },
      methods: {
        // 교사 목록 조회
        getTeacherList(page = 1) {
          console.log("교사 목록 조회 요청:", this.classid, page);
          const data = {
            action: "list",
            classid: this.classid,
            page: page
          };
  
          manageService
            .getTeacherList(data)
            .then(response => {
              console.log("교사 목록 응답:", response.data);
              if (response.data && response.data.teachers) {
                this.teachers = response.data.teachers;
                this.filteredTeachers = [...this.teachers];
                this.grades = response.data.grades;
                this.totalPages = response.data.total_pages;
                this.currentPage = response.data.current_page;
                this.totalRecords = response.data.total_records;
              } else {
                console.error("교사 목록 데이터 형식 오류:", response.data);
                this.$toast.show("교사 목록 데이터 형식이 올바르지 않습니다.", {
                  type: "error",
                  position: "top-right"
                });
              }
            })
            .catch(e => {
              console.error("교사 목록 조회 오류:", e);
              this.$toast.show("교사 목록을 불러오는데 실패했습니다.", {
                type: "error",
                position: "top-right"
              });
            });
        },
  
        // 학년 목록 조회
        getGradeList() {
          const data = {
            action: "grades",
            classid: this.classid
          };
  
          manageService
            .getTeacherList(data)
            .then(response => {
              console.log("학년 목록 응답:", response.data);
              this.grades = response.data;
            })
            .catch(e => {
              console.error("학년 목록 조회 오류:", e);
            });
        },
  
        // 페이지 변경
        changePage(page) {
          if (page < 1 || page > this.totalPages) return;
          this.currentPage = page;
          this.getTeacherList(page);
        },
  
        // 교사 필터링 (이름)
        filterTeachers() {
          this.filteredTeachers = this.teachers.filter(teacher => {
            return !this.searchName || teacher.name.includes(this.searchName);
          });
        },
  
        // 날짜 포맷팅
        formatDate(dateString) {
          if (!dateString) return "";
          const date = new Date(dateString);
          return `${String(date.getMonth() + 1).padStart(2, "0")}-${String(
            date.getDate()
          ).padStart(2, "0")}`;
        },
  
        // 교사 등록 모달 열기
        openAddModal() {
          this.isNew = true;
          this.form = {
            id: "",
            name: "",
            sex: "",
            birthday: "",
            phone: "",
            grade: "",
            passwd: "",
            classid: this.classid
          };
          $("#teacherModal").modal("show");
        },
  
        // 교사 수정 모달 열기
        openEditModal(teacher) {
          this.isNew = false;
          this.form = {
            id: teacher.id,
            name: teacher.name,
            phone: teacher.phone,
            grade: teacher.grade,
            passwd: "",
            classid: this.classid
          };
          $("#teacherModal").modal("show");
        },
  
        // 교사 삭제 확인 모달 열기
        confirmDelete(teacher) {
          this.selectedTeacher = teacher;
          $("#teacherModal").modal("hide");
          setTimeout(() => {
            $("#deleteModal").modal("show");
          }, 500);
        },
  
        // 교사 등록/수정 폼 제출
        submitTeacherForm() {
          const action = this.isNew ? "add" : "update";
          const data = {
            action: action,
            ...this.form
          };
  
          manageService
            .saveTeacher(data)
            .then(response => {
              if (response.data.success) {
                this.$toast.show(response.data.message, {
                  type: "success",
                  position: "top-right"
                });
                $("#teacherModal").modal("hide");
                this.getTeacherList(this.currentPage);
              } else {
                this.$toast.show(response.data.message, {
                  type: "error",
                  position: "top-right"
                });
              }
            })
            .catch(e => {
              console.error("교사 저장 오류:", e);
              this.$toast.show("요청 처리 중 오류가 발생했습니다.", {
                type: "error",
                position: "top-right"
              });
            });
        },
  
        // 교사 삭제
        deleteTeacher() {
          const data = {
            action: "delete",
            id: this.selectedTeacher.id,
            classid: this.classid
          };
  
          manageService
            .saveTeacher(data)
            .then(response => {
              if (response.data.success) {
                this.$toast.show(response.data.message, {
                  type: "success",
                  position: "top-right"
                });
                $("#deleteModal").modal("hide");
                this.getTeacherList(this.currentPage);
              } else {
                this.$toast.show(response.data.message, {
                  type: "error",
                  position: "top-right"
                });
              }
            })
            .catch(e => {
              console.error("교사 삭제 오류:", e);
              this.$toast.show("요청 처리 중 오류가 발생했습니다.", {
                type: "error",
                position: "top-right"
              });
            });
        }
      },
      mounted() {
        console.log("TeacherMgmt 컴포넌트 마운트됨, classid:", this.classid);
        this.getTeacherList();
        this.getGradeList();
      },
    };
  </script>
  
  <style>
    .list {
      text-align: left;
      max-width: 750px;
      margin: auto;
    }
    .done {
      background: oldlace;
    }
  </style>
  